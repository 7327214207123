import ImageAssets from "../assets/images";

export const AppIntegrations = [
  {
    id: 1,
    icon: ImageAssets.Google_Sheets_Logo,
    name: "Google Sheets",
  },
  {
    id: 2,
    icon: ImageAssets.Gmail_Logo,
    name: "Gmail",
  },
  {
    id: 3,
    icon: ImageAssets.Slack_Logo,
    name: "Slack",
  },
  {
    id: 4,
    icon: ImageAssets.Airtable_Logo,
    name: "Airtable",
  },
  {
    id: 5,
    icon: ImageAssets.Notion_Logo,
    name: "Notion",
  },
  {
    id: 6,
    icon: ImageAssets.Pipedrive_Logo,
    name: "Pipedrive",
  },
  {
    id: 7,
    icon: ImageAssets.Mailchimp_Logo,
    name: "Mailchimp",
  },
  {
    id: 8,
    icon: ImageAssets.Hubspot_Logo,
    name: "Hubspot",
  },
  {
    id: 9,
    icon: ImageAssets.Zapier_Logo,
    name: "Zapier",
  },
  {
    id: 10,
    icon: ImageAssets.Google_Analytics_Logo,
    name: "Google Analytics",
  },
  {
    id: 11,
    icon: ImageAssets.Google_Tag_Manager_Logo,
    name: "Google Tag Manager",
  },
  {
    id: 12,
    icon: ImageAssets.Facebook_Pixel_Logo,
    name: "Facebook Pixel",
  },
];
